import React, { useState, useEffect, useRef, useContext } from "react";

import { useHistory, useParams, useLocation } from "react-router-dom";
import { parseISO, format, isSameDay } from "date-fns";
import clsx from "clsx";

import makeStyles from '@mui/styles/makeStyles';
import { green } from "@mui/material/colors";

import {
	Box, Menu,
	Modal, MenuItem,
	Button, Badge, Divider, Avatar, Typography,
	ListItemAvatar, ListItemText, ListItem, IconButton,
	Checkbox, Tooltip
} from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbTack } from '@fortawesome/free-solid-svg-icons';
import { CheckBoxOutlineBlank, CheckBox } from "@mui/icons-material";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ButtonWithSpinner from "../ButtonWithSpinner";
import MarkdownWrapper from "../MarkdownWrapper";
import { AuthContext } from "../../context/Auth/AuthContext";
import toastError from "../../errors/toastError";
import { AiOutlineUser } from "react-icons/ai";
import TicketTime from "../TicketTime";
import { toggleFixedTicket } from "../../helpers/userOptions";
import ContactTag from "../ContactTag";
import ConnectionIcon from "../ConnectionIcon";
import { useTheme } from '@mui/material/styles';
import Ticket from "../../components/Ticket/";
import { FaEye } from "react-icons/fa";
import { useTicketCounts } from '../../context/Tickets/Count';
import { Can } from "../../components/Can";

const useStyles = makeStyles(theme => ({
	listItem: {
		display: 'flex',
		width: '100%',
		justifyContent: 'space-between',
		padding: 0,
		flexDirection: 'column',
		"&:hover": {
			boxShadow: 'inset 0px 0px 26px 0px rgba(0,0,0,0.10)',
			backgroundColor: 'transparent !important'
		},
		'@media (min-width: 600px)': {
			'&:hover $secondaryContentSecond': {
				display: 'flex',
				width: '100%',
				justifyContent: 'space-between',
				alignItems: 'center'

			},
		}
	},
	iconArrow: {
		fontSize: 20,
		color: theme.palette.dark.main
	},
	ticket: {
		width: '100%',
		position: "relative",
		display: 'flex',
		padding: '5px 10px 5px 10px',
		alignItems: 'center',

		'@media (min-width: 600px)': {
			"&:hover $CheckboxTicket": {
				display: "block",
				opacity: 100,
			},
			'&:hover $buttonVol': {
				opacity: 100,
				display: 'none',
			},
			'&$checked $CheckboxTicket': {
				display: 'block',
				opacity: 100,
			},
		}
	},
	tooltip: {
		backgroundColor: "#f5f5f9",
		color: "rgba(0, 0, 0, 0.87)",
		fontSize: theme.typography.pxToRem(14),
		border: "1px solid #dadde9",
		maxWidth: 450,
	},
	tooltipPopper: {
		textAlign: "center",
	},
	buttonVol: {
		display: 'none',
		opacity: 0,
		fontSize: 10,
		transition: "all ease 0.5s",
		position: 'absolute',
		right: 0,
		backgroundColor: 'transparent !important',
		bottom: 10,
		width: 20,
		height: 20
	},
	iconVol: {
		fontSize: 20,
		width: 20
	},
	CheckboxTicket: {
		display: "block",
		opacity: 0,
		transition: "all ease 0.5s",
		width: 20,
		padding: 0
	},
	checked: {},
	pendingTicket: {
		cursor: "unset",
	},

	noTicketsDiv: {
		display: "flex",
		height: "100px",
		margin: 40,
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
	},

	noTicketsText: {
		textAlign: "center",
		color: "rgb(104, 121, 146)",
		fontSize: "14px",
		lineHeight: "1.4",
	},

	noTicketsTitle: {
		textAlign: "center",
		fontSize: "16px",
		fontWeight: "600",
		margin: "0px",
	},

	contactNameWrapper: {
		display: "flex",
		justifyContent: "space-between",
	},

	ConnectionTime: {
		paddingRight: 0,
		display: "flex",
		gap: 10,
		textAlign: 'right'
	},
	/* textBlur:{
		color: 'transparent !important',
		textShadow: '0 0 5px rgba(0, 0, 0, 0.5)',
	},*/

	lastMessageTime: {
		justifySelf: 'flex-end',
		display: 'flex',
		alignItems: 'center',
		gap: '5px',
		fontSize: 12
	},

	closedBadge: {
		alignSelf: "center",
		justifySelf: "flex-end",
		marginRight: 32,
		marginLeft: "auto",
	},
	contactLastMessage: {
		paddingRight: 20,
		fontSize: 13,
		fontWeight: 100,
		height: 20,
		overflow: 'hidden',
		width: '100%',
		float: 'left',
		paddingBottom: 5
	},

	newMessagesCount: {
		position: "absolute",
		alignSelf: "center",
		marginRight: 8,
		marginLeft: "auto",
		marginTop: "-15px",
		left: "35px",
		borderRadius: 0,
	},

	badgeStyle: {
		color: "white",
		backgroundColor: green[500],
	},

	acceptButton: {
		position: "absolute",
		left: "50%",
	},

	ticketQueueColor: {
		flex: "none",
		width: "8px",
		height: "100%",
		position: "absolute",
		top: "0%",
		left: "0%",
	},
	ticketRightBottomBlock: {
		bottom: 5,
		display: "flex"
	},
	userTag: {
		background: 'transparent',
		fontWeight: 700,
		borderRadius: 20,
		marginRight: 0,
		padding: 1,
		paddingLeft: 0,
		paddingRight: 0,
		borderRadius: 3,
		fontSize: "0.8em",
		whiteSpace: "nowrap"
	},
	queueTag: {
		background: "#FCFCFC",
		color: "#000",
		marginRight: 5,
		padding: 1,
		fontWeight: 'bold',
		paddingLeft: 5,
		paddingRight: 5,
		borderRadius: 3,
		fontSize: "0.8em",
		whiteSpace: "nowrap"
	},
	connectionTag: {
		background: "green",
		color: "#FFF",
		marginRight: 5,
		padding: 1,
		fontWeight: 'bold',
		paddingLeft: 5,
		paddingRight: 5,
		borderRadius: 3,
		fontSize: "0.8em",
		whiteSpace: "nowrap"
	},
	ticketRateEmoji: {
		marginRight: 10
	},
	whatsappName: {
		color: "#AAA"
	},
	secondaryContentWrapper: {
		display: 'block'
	},
	secondaryContentFirst: {

	},
	secondaryContentSecond: {
		width: '100%',
		gap: 4,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		boxShadow: 'inset 0px 0px 42px 0px rgba(0,0,0,0.20)'
	},
	cardBottom: {
		overflow: 'hidden',
		marginTop: 10,
		marginBottom: 10,
		display: 'flex',
		flexDirection: 'column'
	},
	connectionIcon: {
		marginRight: theme.spacing(1)
	},
	blockUser: {
		display: 'flex',
		alignItems: 'flex-start',
		justifyContent: 'center',
		gap: 5,
		flexDirection: 'column'
	},
	opened: {
		display: 'flex',
		width: '100%',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	gridTicketItem: {
		paddingBottom: 20
	}

}));

const CustomToolTip = ({ title, content, children }) => {
	const classes = useStyles();

	return (
		<Tooltip
			arrow
			classes={{
				tooltip: classes.tooltip,
				popper: classes.tooltipPopper,
			}}
			title={
				<React.Fragment>
					<Typography gutterBottom color="inherit">
						{title}
					</Typography>
					{content && <Typography>{content}</Typography>}
				</React.Fragment>
			}
		>
			{children}
		</Tooltip>
	);
};


const TicketListItem = ({ ticket, showWhatsappConnection, fixedTickets, disableAnchor, isChecked, onCheckboxChange, mode }) => {
	const classes = useStyles();
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const { ticketId, term } = useParams();
	const isMounted = useRef(true);
	const { user } = useContext(AuthContext);
	const maxVisibleTags = 1;
	const [anchorEl, setAnchorEl] = useState(null);
	const [opened, setOpened] = useState(false);
	const gridMode = mode ? true : false;
	const [openModalTicket, setOpenModalTicket] = useState(false);
	const { handleUpdateCounts } = useTicketCounts();
	const location = useLocation();
	const handleOpenModalTicket = () => {
		setOpenModalTicket(true);
	}

	const handleCloseModalTicket = () => {
		setOpenModalTicket(false);
	}

	const handleClick = (event) => {
		event.stopPropagation();
		setOpened(true);
		setAnchorEl(event.currentTarget);
	};

	const handleClose = (event) => {
		event.stopPropagation();
		setAnchorEl(null);
		setOpened(false);
	};

	const tags = ticket.contact && ticket.contact.tags ? ticket.contact.tags : [];



	const handleCheckboxChange = (event) => {
		const isChecked = event.target.checked;
		onCheckboxChange(ticket.id, isChecked);
	};

	useEffect(() => {
		//console.log(ticket)
		return () => {
			isMounted.current = false;
		};
	}, []);

	const handleAcceptTicket = async id => {
		setLoading(true);
		try {
			const response = await api.put(`/tickets/${id}`, {
				status: "open",
				userId: user?.id,
			});
			if (response) {
				if (isMounted.current) {
					setLoading(false);
				}

				//handleUpdateCounts(ticket.status, '-1');
				//handleUpdateCounts('open', '+1');
				//fetchCounts();
			}

			history.push('/tickets');
			setTimeout(() => {
				history.push(`/tickets/${id}`);
			}, 0);
		} catch (err) {
			setLoading(false);
			toastError(err);
		}


	};

	const fixTicket = (ticket) => {
		try {
			toggleFixedTicket(ticket.id);
			setTimeout(() => {
				history.push(`/tickets/${ticket.id}`);
			}, 100);
		} catch (err) {
			toastError(err);
		}
	}

	const handleSelectTicket = (id) => {
		gridMode && handleOpenModalTicket();
		if (location.pathname.startsWith('/search/tickets')) {
			history.push(`/search/tickets/${term}/${id}`);
		} else {
			history.push(`/tickets/${id}`);
		}
	};

	const renderTicketRate = ticket => {
		const map = {
			'5': '🤩',
			'4': '😀',
			'3': '😐',
			'2': '😤',
			'1': '😡'
		}
		return (
			<span>{ticket.rate ? map[ticket.rate] : ''}</span>
		)
	}

	const canShowTicketRate = () => {
		return user.roleId === 1;
	}



	return (
		<> <Modal
			open={openModalTicket}
			onClose={(event, reason) => {
				if (reason !== 'backdropClick') {
					handleCloseModalTicket();
				}
			}}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box display="flex" className="boxesGridModal" justifyContent="center" alignItems="center">
				<Ticket closeModal={handleCloseModalTicket} />
			</Box>
		</Modal>

			<React.Fragment key={ticket.id} >

				<ListItem
					dense
					button
					className={` ${classes.listItem} listTicketItem `}
					selected={ticketId && +ticketId === ticket.id}
					onClick={() => { handleSelectTicket(ticket.id) }}
				>
					<div className={clsx(classes.ticket, classes.listItemTicket, {
						[classes.pendingTicket]: ticket.status === "pending",
						[classes.checked]: isChecked,
						[classes.gridTicketItem]: gridMode
					})}>
						<Checkbox
							className={clsx(classes.CheckboxTicket)}
							onClick={(e) => {
								e.stopPropagation();
							}}
							onChange={(e) => {
								handleCheckboxChange(e);
							}}		
							checked={isChecked}
							icon={<CheckBoxOutlineBlank style={{ width: 20, height: 20 }} />}
							checkedIcon={<CheckBox style={{ width: 20, height: 20 }} />}
						/>

						<ListItemAvatar
							style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: 10 }}
							onClick={(e) => {
								e.stopPropagation();
								handleSelectTicket(ticket.id);
							}}
						>
							<Avatar src={ticket?.contact?.profilePicUrl} style={{ width: 50, height: 50, border: `3px solid ${ticket.queue ? ticket.queue.color : '#f9f9f9'}` }} />
						</ListItemAvatar>
						<ListItemText
							className={classes.cardBottom}
							disableTypography
							primary={
								<div className={classes.contactNameWrapper}>
									<Typography
										noWrap
										component="span"
										variant="body2"
										color="textPrimary"
									>
										{ticket.contact ? ticket.contact.name : '(Sem Contato)'}
									</Typography>
									{ticket.status === "closed" && (
										<Badge
											className={classes.closedBadge}
											style={{ right: '10px' }}
											badgeContent={"Resolvido"}
											color="primary"
										/>
									)}
								</div>
							}
							secondary={
								<>
									<span className={classes.secondaryContentWrapper}>
										<span className={classes.secondaryContentFirst}>
											{ticket.unreadMessages >= 1 && <Badge
												className={classes.newMessagesCount}
												badgeContent={ticket.unreadMessages}
												classes={{
													badge: classes.badgeStyle,
												}}
											/>}
											{ticket.status === 'group' &&
												<div
													onClick={(e) => {
														fixTicket(ticket);
													}}
													style={{ color: fixedTickets && fixedTickets.indexOf(ticket.id) > -1 ? '#000' : '#CCC' }}
													className={`fixItem ${fixedTickets && fixedTickets.indexOf(ticket.id) > -1 ? 'actived' : ''}`}
												>
													<FontAwesomeIcon icon={faThumbTack} />
												</div>
											}

											{ticket.status === 'pending' && ticket.isGroup &&
												<div
													style={{
														color: '#72C100', fontSize: 12,
														fontWeight: 'bold', right: 10, bottom: 0,
														background: 'transparent', position: 'absolute'
													}}
												>GRUPO</div>
											}

											<div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
												{ticket.lastMessage &&
													<Tooltip
														arrow
														disableFocusListener
														title={ticket.lastMessage}
													>
														<IconButton onClick={(e) => e.stopPropagation()}>
															<FaEye size={14} />
														</IconButton>
													</Tooltip>
												}
												<Typography
													className={classes.contactLastMessage}
													noWrap
													component="span"
													variant="body2"
													color="textSecondary"
													onClick={(e) => {
														e.stopPropagation();
														handleSelectTicket(ticket.id);
													}}
												>
													{ticket.lastMessage ? (
														<MarkdownWrapper disableAnchor={disableAnchor}>
															{ticket.lastMessage.indexOf('[') === 0 ? i18n.t(`messages.${ticket.lastMessage}`) : ticket.lastMessage}
														</MarkdownWrapper>
													) : (
														<br />
													)}
												</Typography>
											</div>
										</span>
									</span>

									<div className={classes.blockUser} style={tags.length > 0 ? { paddingLeft: 10 } : {}}>
										{tags.length > 0 && (
											<div style={{ display: 'flex', gap: 10 }}>
												{tags.slice(0, maxVisibleTags).map((tag) => (
													<div key={`ticket-contact-tag-${ticket.id}-${tag.id}`} onClick={(e) => e.stopPropagation()}>
														<ContactTag tag={tag} />
													</div>
												))}
												{tags.length > maxVisibleTags && (
													<>
														<Button
															style={{
																minWidth: 'initial',
																padding: 0, marginLeft: -5,
																background: '#f5f5f5', width: 14,
																height: 14, borderRadius: 100,
																fontSize: 11, fontWeight: 'bold'
															}}
															aria-controls="tags-menu"
															aria-haspopup="true"
															onMouseEnter={handleClick}
															onClick={handleClick}
														>
															+{tags.length}
														</Button>
														<Menu
															id="tags-menu"
															anchorEl={anchorEl}
															open={Boolean(anchorEl)}
															onClick={handleClose}
														>
															<div style={{ display: 'flex', flexDirection: 'column' }} onMouseLeave={handleClose}>
																{tags.map((tag) => (
																	<MenuItem key={`ticket-contact-tag-${ticket.id}-${tag.id}`} onClick={(e) => e.stopPropagation()}>
																		<ContactTag tag={tag} />
																	</MenuItem>
																))}
															</div>
														</Menu>
													</>
												)}
											</div>
										)}

										{ticket.queue && (
											<div style={{ display: 'flex', gap: 10, bottom: 0 }}>
												<ContactTag tag={ticket.queue} key={`${ticket.queue.name}`} />
											</div>
										)}
									</div>
								</>
							}
						/>
						<div className={gridMode ? 'infoListTicket' : ''}
							onClick={(e) => {
								e.stopPropagation();
								handleSelectTicket(ticket.id);
							}}
							style={{
								display: 'flex',
								flexDirection: gridMode ? 'row' : 'column',
								alignItems: 'flex-end',
								minWidth: '33%',
								paddingTop: ticket.status === 'group' ? 15 : 0
							}}
						>
							<span className={classes.ConnectionTime}>
								{ticket.lastMessage && (
									<Typography
										className={classes.lastMessageTime}
										component="span"
										variant="body2"
										color="textSecondary"
									>
										{isSameDay(parseISO(ticket.updatedAt), new Date()) ? (
											<>{format(parseISO(ticket.updatedAt), "HH:mm")}</>
										) : (
											<><TicketTime updatedAt={ticket.updatedAt} /></>
										)}
									</Typography>
								)}
							</span>

							{ticket.user && (
								<div className={classes.blockUser}>
									<div className={classes.userTag}
										style={{
											color: '#666666',
											fontSize: 11, display: 'flex', alignItems: 'center'
										}}
									>
										<AiOutlineUser /> {ticket.user.name}
									</div>
								</div>
							)}

							<div style={{
								color: '#666666',
								fontSize: 11, display: 'flex', alignItems: 'center'
							}}
							>
								<>
									{ticket.type &&
										<ConnectionIcon className={classes.connectionIcon} connectionType={ticket.type} />
									}

									{ticket.whatsapp && ticket.whatsapp.name &&
										<span>{ticket.whatsapp.name}</span>
									}
								</>
							</div>
						</div>
						{(ticket.status === "pending" || ticket.status === "bot") && (
							<ButtonWithSpinner
								style={{ backgroundColor: '#2ecc71', color: '#000', padding: '0px', borderRadius: '0px', bottom: mode ? '20px' : '0px', left: mode ? '25px' : '8px', fontSize: '0.6rem' }}
								variant="contained"
								className={classes.acceptButton}
								size="small"
								loading={loading}
								onClick={(e) => {
									e.stopPropagation();
									handleAcceptTicket(ticket.id);
								}}
							>
								{i18n.t("ticketsList.buttons.accept")}
							</ButtonWithSpinner>
						)}
					</div>
				</ListItem>

				<Divider variant="inset" component="li" style={{ marginLeft: 0 }} />
			</React.Fragment>
		</>
	);
};

export default TicketListItem;
