import React, { useState, useEffect, useContext } from "react";
import api from "../../services/api";
import toastError from "../../errors/toastError";

import { Grid, Paper, useTheme } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

import DashboardBox from "./Box";
import DashboardInfoBox from "./BoxDash";
import { Computer, Contacts, DoneAll, Pause, Forum, Group, List, LocalOffer, People, PhonelinkRing, Queue, Schedule, Timer } from "@mui/icons-material";
import DashboardUsersBox from "./UsersBox";
import DateRangePicker from "../../components/DateRangePicker";
import SelectContacts from "../../components/SelectContacts";
import SelectUsers from "../../components/SelectUsers";
import SelectQueues from "../../components/SelectQueues";
import SelectTags from "../../components/SelectTags";
import { AuthContext } from "../../context/Auth/AuthContext";
import ChartTickets from "./Chart";
import ChartUsers from "./ChartUsers";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles(theme => ({
    root: {
        '& h2': {
            color: theme.palette.dark.main
        }
    },
    paper: {
        padding: theme.spacing(2),
        boxShadow: "none",
        borderRadius: "20px !important",
        background: theme.palette.background.paper,
        color: theme.palette.dark.main,
        transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
        cursor: 'pointer',
        '&:hover': {
            transform: 'scale(1.008)',
            boxShadow: '0px 0px 26px 0px rgba(255,255,255,1)',
        }
    },
    paperInfo: {
        //padding: theme.spacing(2),
        boxShadow: "none",
        borderRadius: "20px !important",
        background: 'transparent',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: 3,
        overflow: 'auto'
    },
    paperFilters: {
        padding: theme.spacing(2),
        boxShadow: "none",
        borderRadius: "20px !important",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        gap: 3
    },
    wrapperSelect: {
        marginBottom: 37
    }
}));

const ResumeChart = () => {

    const [chartOptions, setChartOptions] = useState({});
    const colorCharts = "#2980b9";
    const [loading, setLoading] = useState(false);
    const [chartSeries, setChartSeries] = useState([]);
    const [ticketsData, setTicketsData] = useState({});
    const [dateFrom, setDateFrom] = useState(new Date());
    const [dateTo, setDateTo] = useState(new Date());

    const [userIds, setUserIds] = useState([]);
    const [contactIds, setContactIds] = useState([]);
    const [queueIds, setQueueIds] = useState([]);
    const [tagIds, setTagIds] = useState([]);
    const [listUsers, setListUsers] = useState([]);
    const { user } = useContext(AuthContext);
    const theme = useTheme();
    const classes = useStyles();
    const [radarChartData, setChartData] = useState([]);
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            const fetchContacts = async () => {
                try {
                    const params = {
                        dateFrom: dateFrom.toJSON().slice(0, 10),
                        dateTo: dateTo.toJSON().slice(0, 10),
                        userIds: userIds && userIds.length >= 1 ? userIds : user.roleId === 1 ? null : [user.id],
                        queueIds,
                        contactIds,
                        tagIds
                    }
                    const { data } = await api.get("/dashboard/resume/", { params });
                    setChartSeries(data.series);
                    setTicketsData(data);
                    const updatedUsers = data.users.map(user => {
                        if (!user.avatar) {
                            user.avatar = { src: '/avatar-person.svg' };
                        } else {
                            user.avatar = { src: `${backendUrl}/public/app/${user.avatar.src}` };
                        }
                        return user;
                    });
                    //console.log(updatedUsers)
                    setListUsers(updatedUsers);

                    function resolvedPercentage(item) {
                        return (item / data.total) * 100;
                    }

                    setChartData([{
                        category:i18n.t("dashboardHome.status.pending"),
                        value: resolvedPercentage(data.pending),
                        full: data.total
                    }, {
                        category: i18n.t("dashboardHome.status.open"),
                        value: resolvedPercentage(data.open),
                        full: data.total
                    }, {
                        category: i18n.t("dashboardHome.status.paused"),
                        value: resolvedPercentage(data.paused),
                        full: 100
                    },
                    {
                        category: i18n.t("dashboardHome.status.resolved"),
                        value: resolvedPercentage(data.closed),
                        full: 100
                    }]);

                } catch (err) {
                    toastError(err);
                }
                setLoading(false);
            };
            fetchContacts();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [dateFrom, dateTo, userIds, contactIds, queueIds, tagIds]);

    useEffect(() => {
        setChartOptions(
            {
                chart: {
                    plotBackgroundColor: theme.palette.background.paper,
                    backgroundColor: theme.palette.background.paper,                    
                    alternativeText: theme.palette.textPrimary,
                   // fill: theme.palette.textPrimary,
                     color: theme.palette.textPrimary,
                    plotBorderWidth: null,
                    plotShadow: false,
                    type: 'pie'
                },
                label: {
                    fill: theme.palette.textPrimary,  
                },
                title: {
                    text: theme.palette.textPrimary
                },
                tooltip: {
                    pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                },
                series: chartSeries,
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: 'pointer',
                        dataLabels: {
                            enabled: true,
                            format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                            text: theme.palette.text.main,
                        }
                    }
                }
            },
        );
    }, [chartSeries, theme]);

    const handleChangeUserIds = (values) => {
        setUserIds(values.map((item) => item.id));
    }

    const handleChangeQueueIds = (values) => {
        setQueueIds(values.map((item) => item.id));
    }
    const handleChangeContactIds = (values) => {
        setContactIds(values.map((item) => item.id));
    }
    const handleChangeTagIds = (values) => {
        setTagIds(values.map((item) => item.id));
    }

    useEffect(() => {
        async function getReportUser() {
            if (user && user.profile === 'user') {
                await setUserIds([user.id]);
            }
        }
        getReportUser();
    }, [user]);

    return (
        <Grid container spacing={3} className={classes.root}>
            <Grid item xs={12} sm={12} md={12}>
                <Paper className={classes.paperInfo}>
                    <Grid item xs={12} sm={2} md={2}>
                        <DashboardInfoBox className={classes.paper} loading={loading} color="#F61C05" title={i18n.t("dashboardHome.status.pending")} value={ticketsData.pending} icon={<Timer fontSize="inherit" />} />
                    </Grid>
                    <Grid item xs={12} sm={2} md={2}>
                        <DashboardInfoBox className={classes.paper} loading={loading} color="#F1C40F" title={i18n.t("dashboardHome.status.paused")} value={ticketsData.paused} icon={<Pause fontSize="inherit" />} />
                    </Grid>
                    <Grid item xs={12} sm={2} md={2}>
                        <DashboardInfoBox className={classes.paper} loading={loading} color="#009FDD" title={i18n.t("dashboardHome.status.resolved")} value={ticketsData.closed} icon={<DoneAll fontSize="inherit" />} />

                    </Grid>
                    <Grid item xs={12} sm={2} md={2}>
                        <DashboardInfoBox className={classes.paper} loading={loading} color="#2ECC71" title={i18n.t("dashboardHome.status.online")} value={ticketsData.open} icon={<Group fontSize="inherit" />} />
                    </Grid>
                    <Grid item xs={12} sm={2} md={2}>
                        <DashboardInfoBox
                            className={classes.paper}
                            loading={loading}
                            color="#9B59B6"
                            title={i18n.t("dashboardHome.status.bot")}
                            value={ticketsData.bot}
                            icon={<Computer fontSize="inherit" />} />
                    </Grid>

                    <Grid item xs={12} sm={2} md={2}>
                        <DashboardInfoBox className={classes.paper} loading={loading} color="#2ECC71" title="Total" value={ticketsData.total} icon={<Group fontSize="inherit" />} />
                    </Grid>

                </Paper>
            </Grid>

            <Grid item xs={12} sm={6} md={2}>
                <Paper className={classes.paperFilters}>
                    <label>{i18n.t("dashboardHome.filters")}</label>
                    {user.profile === 'admin' ? (
                        <>
                            <Grid item xs={12} md={12}>
                                <div className={classes.wrapperSelect}>
                                    <DateRangePicker disabled={loading} onChange={(p) => { setDateFrom(p[0]); setDateTo(p[1]) }} />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <div className={classes.wrapperSelect}>
                                    <SelectContacts disabled={loading} onChange={handleChangeContactIds} />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <div className={classes.wrapperSelect}>
                                    <SelectUsers multiple disabled={loading} onChange={handleChangeUserIds} />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <div className={classes.wrapperSelect}>
                                    <SelectQueues disabled={loading} onChange={handleChangeQueueIds} />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <div className={classes.wrapperSelect}>
                                    <SelectTags outlined disabled={loading} onChange={handleChangeTagIds} />
                                </div>
                            </Grid>
                        </>) : (
                        <>
                            <Grid item xs={12} md={12}>
                                <div className={classes.wrapperSelect}>
                                    <DateRangePicker disabled={loading} onChange={(p) => { setDateFrom(p[0]); setDateTo(p[1]) }} />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <div className={classes.wrapperSelect}>
                                    <SelectContacts disabled={loading} onChange={handleChangeContactIds} />
                                </div>
                            </Grid>
                        </>
                    )}
                </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
                <DashboardBox className={classes.paper} value={<React.Fragment>
                    <ChartTickets data={radarChartData} color={colorCharts} />
                </React.Fragment>} />
            </Grid>{/**
            <Grid item xs={12} sm={6} md={6}>
                <DashboardBox className={classes.paper} loading={loading} title="Total de Atendimentos" value={ticketsData.total} />
                <DashboardBox marginTop={20} className={classes.paper} loading={loading} title="Total de Contatos" value={ticketsData.totalContacts} />
            </Grid> */}
            <Grid item xs={12} sm={6} md={5}>
                <DashboardBox className={classes.paper} value={<React.Fragment>
                    <ChartUsers data={listUsers} color={colorCharts} />
                </React.Fragment>} />
            </Grid>

            {ticketsData.stats && (<>
                <Grid item xs={12} sm={12} md={12}>
                    <Paper className={classes.paperInfo}>

                        <Grid item xs={12} sm={6} md={2}>
                            <DashboardInfoBox title={i18n.t("dashboardHome.attendants")} icon={<People fontSize="inherit" />} value={ticketsData.stats.usersNumber} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                            <DashboardInfoBox title={i18n.t("dashboardHome.connections")} icon={<PhonelinkRing fontSize="inherit" />} value={ticketsData.stats.connectionsNumber} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                            <DashboardInfoBox title={i18n.t("dashboardHome.contacts")} icon={<Contacts fontSize="inherit" />} value={ticketsData.stats.contactsNumber} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                            <DashboardInfoBox title={i18n.t("dashboardHome.groups")} icon={<Group fontSize="inherit" />} value={ticketsData.stats.groupsNumber} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                            <DashboardInfoBox title={i18n.t("dashboardHome.messages")} icon={<Forum fontSize="inherit" />} value={ticketsData.stats.messagesNumber} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                            <DashboardInfoBox title="Tags" icon={<LocalOffer fontSize="inherit" />} value={ticketsData.stats.tagsNumber} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <DashboardInfoBox title={i18n.t("dashboardHome.appointments_sents")} icon={<Schedule fontSize="inherit" />} value={`${ticketsData.stats.scheduledMessagesNumber} / ${ticketsData.stats.scheduledMessagesSentNumber}`} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                            <DashboardInfoBox title={i18n.t("dashboardHome.queues")} icon={<Queue fontSize="inherit" />} value={ticketsData.stats.queuesNumber} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                            <DashboardInfoBox title={i18n.t("dashboardHome.bots")} icon={<Computer fontSize="inherit" />} value={ticketsData.stats.botsNumber} />
                        </Grid>
                    </Paper>
                </Grid>
            </>
            )}

            {ticketsData.users &&
                <>
                    <Grid item xs={12} sm={12} md={12}>
                        <h5>{i18n.t("dashboardHome.attendants")}</h5>
                    </Grid>
                    {ticketsData.users && ticketsData.users.map((user) =>
                        <Grid item x={12} sm={12} md={12} key={`user_box_${user.id}`} className={classes.blockStats}>
                            <DashboardUsersBox className={classes.paper} user={user} />
                        </Grid>
                    )}
                </>
            }
            
            {ticketsData.queues && (<>
                <Grid item xs={12} sm={12} md={12}>
                    <h5 style={{ fontWeight: 'bold' }}>{i18n.t("dashboardHome.queues")}</h5>
                </Grid>
                {ticketsData.queues.map(queue => (
                    <Grid item xs={12} sm={6} md={3} key={`queue_box_${queue.id}`}>
                        <DashboardInfoBox title={queue.name} icon={<List fontSize="inherit" />} value={queue.users.length} label={i18n.t("dashboardHome.attendants")} />
                    </Grid>
                ))}
            </>)}


        </Grid>
    );
};

export default ResumeChart;
