import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import makeStyles from '@mui/styles/makeStyles';
import Paper from "@mui/material/Paper";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import TicketsQueueSelect from "../TicketsQueueSelect";
import NewTicketModal from "../NewTicketModal";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import TicketsList from "../TicketsList";

import { AuthContext } from "../../context/Auth/AuthContext";
import { SettingsContext } from "../../context/Settings/SettingsContext";
import toastError from "../../errors/toastError";
import api from "../../services/api";

import getSocket from "../../helpers/socket";
import useCountPaused from '../../hooks/countMessages';
import { PiRobot } from "react-icons/pi";
import { HiOutlineUsers } from "react-icons/hi2"
import { Can } from "../../components/Can";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Checkbox, ListItemText } from "@mui/material";
import { useTicketCounts } from '../../context/Tickets/Count';
import { i18n } from "../../translate/i18n";
import chroma from "chroma-js";
import { GoSortAsc, GoSortDesc } from "react-icons/go";

import {
    Box,
    Typography,
    List,
    ListItem,
    Button,
    Chip,
    Grid,
    Tooltip,
    OutlinedInput,
    InputLabel
} from "@mui/material";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import RefreshIcon from '@mui/icons-material/Refresh';
import { FaEye, FaMinus, FaPlus } from "react-icons/fa";
import IOSSwitch from "../IOSSwitch";
import AsyncSelect from "../AsyncSelect";
import SelectTags from "../SelectTags";
import SelectStatus from "../SelectStatus";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { endOfDay } from "date-fns";
import { red } from "@mui/material/colors";

const columns = {
    NoBot: [],
    [i18n.t("columnName.pending")]: [],
    [i18n.t("columnName.open")]: [],
    [i18n.t("columnName.paused")]: [],
    [i18n.t("columnName.closed")]: [],
    [i18n.t("columnName.group")]: [],
};

const socket = getSocket();

const useStyles = makeStyles(theme => ({
    ticketsWrapperRadius: {
        position: "relative",
        display: "flex",
        height: "85vh",
        flexDirection: "column",
        overflow: "hidden",
        borderRadius: 20,
        background: theme.palette.inputBackground,
        border: 0,
    },
    headH6: {
        padding: '5px 20px',
        fontSize: 18,
        fontWeight: 'bold',
        justifyContent: 'space-between'
    },
    ticketsWrapper: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        border: 0,
    },
    gridDisabled: {
        backgroundColor: '#f5f5f5',
        position: 'relative',
    },
    gridDisabledOverlay: {
        content: ' ',
        position: 'absolute',
        width: '100%',
        height: '100%',
        zIndex: 9,
    },
    tabsHeader: {
        flex: "none",
    },

    tabsInternal: {
        flex: "none",
        backgroundColor: theme.palette.tabHeaderBackground
    },

    settingsIcon: {
        alignSelf: "center",
        marginLeft: "auto",
        padding: 8,
    },

    tab: {
        minWidth: 90,
        width: 90,
        textTransform: 'capitalize',
    },

    internalTab: {
        minHeight: 70,
        minWidth: 120,
        width: 120,
        padding: 5,
        borderRight: '1px solid #f9f9f9',
        color: theme.palette.mode === 'dark' ? '#fff' : '#696969'
    },
    resetButton: {
        position: 'absolute',
        right: 5,
        top: 5,
        backgroundColor: '#ffffff'
    },
    ticketOptionsBox: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        background: theme.palette.optionsBackground,
        boxShadow: 'inset 0px 0px 26px 0px rgba(0,0,0,0.05)',
        padding: theme.spacing(1),
    },

    ticketSearchLine: {
        padding: theme.spacing(1),
        backgroundColor: theme.palette.mode === 'dark' ? '#000000' : "#FFFFFF",
        borderRadius: 25,
        width: "98.7%",
        margin: 10,
        padding: 20,
    },

    searchInputHeader: {
        display: "flex",
        width: "15%",
        position: "relative",
        justifyContent: "end",
        marginRight: 10,
        borderRadius: 4,
        border: "1px solid",
        fontFamily: "Poppins, sans-serif",
        alignItems: "center",
        fontSize: 12,
        color: theme.palette.mode === 'dark' ? '#fff' : 'rgba(0, 0, 0, 0.40)',
        height: 35,
        borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.30)' : 'rgba(0, 0, 0, 0.20)',
        '&:hover': {
            borderColor: theme.palette.mode === 'dark' ? '#fff' : '#000000',
        },
    },

    refreshIcon: {
        marginTop: 5,
        marginRight: 5,
        color: theme.palette.mode === 'dark' ? '#fff' : 'rgba(0, 0, 0, 0.54)',
    },

    searchInputWrapper: {
        flex: 18,
        background: theme.palette.total,
        display: "flex",
        borderRadius: 40,
        padding: 4,
        marginRight: theme.spacing(1),
        height: 40,
        alignItems: "center",
    },

    searchToggleShow: {
        marginRight: 10,
        color: theme.palette.mode === 'dark' ? '#fff' : 'rgba(0, 0, 0, 0.54)',
    },

    textSearch: {
        marginRight: 10,
    },

    searchToggleIcon: {
        marginTop: 5
    },

    searchIcon: {
        color: theme.palette.primary.main,
        marginLeft: 6,
        marginRight: 6,
        alignSelf: "center",
    },

    searchInput: {
        flex: 1,
        border: "none",
        borderRadius: 30,
    },

    insiderTabPanel: {
        height: '89%',
        marginTop: "0px",
        paddingTop: "0px"
    },

    insiderDoubleTabPanel: {
        display: "flex",
        flexDirection: "column",
        marginTop: "-72px",
        paddingTop: "72px",
        height: "100%"
    },

    labelContainer: {
        width: "auto",
        padding: 0
    },
    iconLabelWrapper: {
        flexDirection: "row",
        '& > *:first-child': {
            marginBottom: '3px !important',
            marginRight: 16
        }
    },
    insiderTabLabel: {
        [theme.breakpoints.down(1600)]: {
            display: 'none'
        }
    },
    smallFormControl: {
        '& .MuiOutlinedInput-input': {
            padding: "9.5px",
        },
        '& .MuiInputLabel-outlined': {
            marginTop: "-6px"
        },
    },
    FormControl: {
        border: '1px solid #bdbdbd',
        borderRadius: 4,
        padding: "5px 10px",
        width: '100%'
    },
    boxGridTickets: {
        borderRadius: 20,
        ...theme.scrollbarStylesSoft
    },
    paperBoxes: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
    },
    boxMinimized: {
        height: 40,
        overflow: 'hidden'
    },
    boxColumnButton: {
        background: 'transparent',
        fontSize: 12
    },
    buttonFilter: {
        marginTop: 7,
    }
}));

const TicketsGridManager = () => {
    const classes = useStyles();
    const { getSettingValue } = useContext(SettingsContext);
    const [showBotList, setShowBotList] = useState(true);
    const [showAllTickets, setShowAllTickets] = useState(false);
    const [sortAscending, setSortAscending] = useState(false);
    const { user, permissions } = useContext(AuthContext);
    const [tagIds, setTagIds] = useState([]);
    const { countPaused, setCountPaused } = useCountPaused();
    const [countPending, setCountPending] = useState(0);
    const [countGroupUnread, setCountGroupUnread] = useState(0);
    const [countBot, setCountBot] = useState(0);
    const localStorageQueueIds = window.localStorage.getItem('selectedQueueIds') ? JSON.parse(window.localStorage.getItem('selectedQueueIds')) : null;
    const userQueueIds = localStorageQueueIds ? localStorageQueueIds : user && user.queues ? user.queues.map(q => q.id) : [-1];
    const [selectedQueueIds, setSelectedQueueIds] = useState([]);
    const [tasks, setTasks] = useState(columns);
    const [selectModeList, setSelectModeList] = useState([
        i18n.t("columnName.pending"),
        i18n.t("columnName.paused"),
        i18n.t("columnName.closed"),
        i18n.t("columnName.open"),
        i18n.t("columnName.group"),
        'NoBot']);
    const [showSelected, setShowSelected] = useState(null);
    const [gridOpened, setOpened] = useState({
        [i18n.t("columnName.pending")]: true,
        [i18n.t("columnName.paused")]: true,
        [i18n.t("columnName.closed")]: true,
        [i18n.t("columnName.open")]: true,
        [i18n.t("columnName.group")]: true,
        'NoBot': true,
    });
    const { countTickets } = useTicketCounts();
    const [searchParam, setSearchParam] = useState("");
    const [searchById, setSearchByTicketId] = useState(null);
    const [searchOnMessages, setSearchOnMessages] = useState(false);
    const [disableControls, setDisableControls] = useState(false);
    const [showSearchBar, setShowSearchBar] = useState(false);
    const [userIds, setUserIds] = useState([]);
    const [connectionIds, setConnectionIds] = useState([]);
    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);
    const [selectedStatuses, setSelectedStatuses] = useState([]);
    const [forceSearch, setForceSearch] = useState(false);
    const [isSearched, setisSearched] = useState(false);
    const [internalSelectedQueueIds, setInternalSelectedQueueIds] = useState([]);
    const [selectedUnreadMessageOption, setSelectedUnreadMessage] = useState('');
    const [unreadMessagesSearchMe, setUnreadMessagesSearchMe] = useState(false);
    const [unreadMessagesSearchClient, setUnreadMessagesSearchClient] = useState(false);
    const [lastClientActivityMe, setLastClientActivityMe] = useState(null);
    const [lastClientActivityClient, setLastClientActivityClient] = useState(null);
    const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
    const inputRefDateFrom = useRef();
    const inputRefDateTo = useRef();
    const searchInputRef = useRef();
    const searchTicketIdRef = useRef();
    const [listQueues, setListQueues] = useState({});
    const [listTags, setListTags] = useState({});
    const [listConnections, setListConnections] = useState({});
    const [listUsers, setListUsers] = useState({});
    const { term } = useParams();
    const history = useHistory();
    const userCanInteractWithAllGroups = permissions.some((permission) => permission.key === 'contacts:groups:see-all');

    useEffect(() => {
        setSearchParam(term);
    }, [term])

    const handleUnanswered = (event) => {
        const target = event.target.value;
        setSelectedUnreadMessage(target);
        switch (target) {
            case 'allMe':
                setUnreadMessagesSearchMe(true);
                setUnreadMessagesSearchClient(null);
                setLastClientActivityClient(null);
                setLastClientActivityMe(null);
                break;
            case 'allClient':
                setUnreadMessagesSearchMe(null);
                setUnreadMessagesSearchClient(true);
                setLastClientActivityClient(null);
                setLastClientActivityMe(null);
                break;
            case 'customClient':
                setUnreadMessagesSearchMe(null);
                setUnreadMessagesSearchClient(null);
                setLastClientActivityClient(true);
                setLastClientActivityMe(null);
                break;
            case 'customMe':
                setUnreadMessagesSearchMe(null);
                setUnreadMessagesSearchClient(null);
                setLastClientActivityClient(null);
                setLastClientActivityMe(true);
                break;
            default:
                setUnreadMessagesSearchMe(null);
                setUnreadMessagesSearchClient(null);
                setLastClientActivityClient(null);
                setLastClientActivityMe(null);
        }
    };

    const handleUpdateCounts = (status) => {
        countTickets(status, showAllTickets, selectedQueueIds, tagIds);
    };

    const handleColumnClick = (columnName) => {
        setOpened((prevGridOpened) => ({
            ...prevGridOpened,
            [columnName]: !prevGridOpened[columnName],
        }));
    };

    const onDragOver = (e) => {
        e.preventDefault();
    };

    useEffect(() => {
        const localQueueIds = JSON.parse(window.localStorage.getItem('selectedQueueIds'));
        const selectedModeLocalList = JSON.parse(window.localStorage.getItem('selectedModeList'));
        if (localQueueIds) {
            setSelectedQueueIds(localQueueIds)
        }

        if (selectedModeLocalList && selectedModeLocalList.length >= 1) {
            setSelectModeList(selectedModeLocalList)
        }
    }, []);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            const fetchList = async () => {
                try {
                    const { data } = await api.get('/bots/enabled');
                    setShowBotList(data.enabled);
                } catch (err) {
                    toastError(err);
                }
            };
            fetchList();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, []);

    useEffect(() => {
        const socket = getSocket(true);
        const connectEvent = () => socket.emit("joinNotification");
        const appMessageEvent = data => {
            if (
                data.action === "create" &&
                !data.message.read &&
                (data.ticket.userId === user?.id || !data.ticket.userId)
            ) {
                if (data.ticket.status == 'paused') {
                    let pauseds = countPaused + 1;
                    setCountPaused(Number(pauseds));
                }
            }
        }
        socket.on("connect", connectEvent);
        socket.on("appMessage", appMessageEvent);

        if (socket.connected) {
            connectEvent();
        }

        return () => {
            socket.disconnect();
        };
    }, [user, countPaused, setCountPaused]);

    useEffect(() => {
        const botEvent = data => {
            if (data.type === "create" || data.type === "update") {
                if (data.bot.first) {
                    setShowBotList(data.enabledBotStatus);
                }
            }
        }
        socket.on("bot", botEvent);
        return () => {
            socket.off("bot", botEvent);
        };
    }, []);

    const handleChangeModeList = async (data) => {
        setSelectModeList(data);
        window.localStorage.setItem('selectedModeList', JSON.stringify(data));
    };

    let searchTimeout;

    const handleChangeSearch = e => {
        const searchedTerm = e.target.value.toLowerCase();
        clearTimeout(searchTimeout);
        if (searchedTerm === "") {
            setSearchParam(searchedTerm);
            return;
        }
        searchTimeout = setTimeout(() => {
            setSearchParam(searchedTerm);
        }, 500);
    };

    let searchTimeoutTicketId;

    const handleChangeTicketId = e => {
        const searchedId = e.target.value;
        clearTimeout(searchTimeoutTicketId);
        if (searchedId === "") {
            setSearchByTicketId(searchedId);
            return;
        }
        searchTimeoutTicketId = setTimeout(() => {
            setSearchByTicketId(searchedId);
        }, 500);
    };

    const handleSearch = () => {
        setSelectedQueueIds(internalSelectedQueueIds);
        setForceSearch(!forceSearch);
        setShowSearchBar(false);
        setisSearched(true);
    };

    const handleClean = () => {
        history.push(`/tickets/`);
        setShowSearchBar(true);
        setSearchParam("");
        setSearchByTicketId(null);
        setUserIds([]);
        setTagIds([]);
        setConnectionIds([]);
        setDateFrom(null);
        setDateTo(null);
        setisSearched(false);
        setSelectedStatuses([]);
        setSelectedQueueIds([]);
        setInternalSelectedQueueIds([]);
        setForceSearch(prev => !prev);
    };

    const handleSortAscending = () => {
        setSortAscending(!sortAscending)
        localStorage.setItem('sortAscending', !sortAscending)
    }


    const getStatusColumnName = (status) => {
        switch (status) {
            case 'paused':
                return i18n.t("columnName.paused");
            case 'open':
                return i18n.t("columnName.open");
            case 'pending':
                return i18n.t("columnName.pending");
            case 'closed':
                return i18n.t("columnName.closed");
            case 'group':
                return i18n.t("columnName.group");
            case 'NoBot':
                return 'NoBot';
            default:
                return null;
        }
    };

    const handleCheckboxChange = (value) => {
        if (selectModeList.includes(value)) {
            setSelectModeList(selectModeList.filter(item => item !== value));
        } else {
            setSelectModeList([...selectModeList, value]);
        }
    };

    return (
        <>
           <NewTicketModal
                modalOpen={newTicketModalOpen}
                onClose={e => setNewTicketModalOpen(false)}
            />
            <Box style={{ width: '99%', display: 'flex', justifyContent: 'end', alignItems: 'center', gap: 20, marginTop: '-25px', marginBottom: 10 }}>
                <Can
                    role={user.roleId}
                    perform={"tickets:create"}
                    yes={() => (
                        <Button
                            variant="contained"
                            size="small"
                            style={{
                                background: "#2ecc71", boxShadow: 'none', borderRadius: 10, border: 0,
                                color: '#000', gap: 3, fontWeight: 'bold', height: 30
                            }}
                            onClick={() => setNewTicketModalOpen(true)}
                        >
                            <FaPlus size={12} /> {i18n.t("ticketsManager.buttons.newTicket")}
                        </Button>
                    )}
                    no={() => <></>}
                />

                <Tooltip placement="bottom" title={i18n.t("tickets.buttons.sortAscending")}>
                    <FormControlLabel
                        style={{ display: 'flex', alignItems: 'center' }}
                        label={
                            sortAscending ?
                                <GoSortDesc size={28} color={sortAscending ? '#2ecc71' : '#ddd'} style={{ marginTop: 5 }} /> :
                                <GoSortAsc size={28} color={sortAscending ? '#2ecc71' : '#ddd'} style={{ marginTop: 5 }} />
                        }
                        labelPlacement="start"
                        control={
                            <Switch
                                size="small"
                                checked={sortAscending}
                                onChange={() => handleSortAscending()}
                                name="sortAscending"
                                color="primary"
                            />
                        }
                    />
                </Tooltip>

                <Can
                    role={user.roleId}
                    perform="tickets:view:all"
                    yes={() => (
                        <FormControlLabel
                            label={<HiOutlineUsers size={28} color={showAllTickets ? '#2ecc71' : '#ddd'} style={{ marginTop: 5 }} />}
                            labelPlacement="start"
                            control={
                                <Switch
                                    size="small"
                                    checked={showAllTickets}
                                    onChange={() =>
                                        setShowAllTickets(prevState => !prevState)
                                    }
                                    name="showAllTickets"
                                    label="Ver todos atendimentos"
                                    color="success"
                                />
                            }
                        />)}
                    no={() => <></>}
                />

                <Box className={classes.searchInputHeader} onClick={() => setShowSearchBar(prevState => !prevState)}>
                    <SearchIcon />
                    <div className={classes.textSearch}>{i18n.t("search.search")}</div>
                    <div className={classes.searchToggleShow}>
                        {showSearchBar ? (
                            <ArrowUpward onClick={(e) => { e.stopPropagation(); setShowSearchBar(false); }} className={classes.searchToggleIcon} />
                        ) : (
                            <ArrowDownward onClick={(e) => { e.stopPropagation(); setShowSearchBar(true); }} className={classes.searchToggleIcon} />
                        )}
                    </div>
                    <div>
                        <RefreshIcon onClick={(e) => { e.stopPropagation(); handleClean(); }} className={classes.refreshIcon} />
                    </div>
                </Box>



                <TicketsQueueSelect
                    style={{ marginLeft: 6 }}
                    selectedQueueIds={selectedQueueIds}
                    userQueues={user?.queues}
                    onChange={values => setSelectedQueueIds(values)}
                />

                <div style={{ width: 70 }}>
                    <FormControl variant="outlined" fullWidth>
                        <Select
                            multiple
                            displayEmpty
                            value={selectModeList}
                            size="small"
                            style={{ height: 35, borderRadius: 20 }}
                            onChange={(data) => handleChangeModeList(data.target.value)}
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                },
                                transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                },
                                getContentAnchorEl: null,
                            }}
                            renderValue={() => {
                                if (!showSelected || !selectModeList || selectModeList.length === 0) {
                                    return <FaEye style={{ paddingTop: 7 }} />;
                                } else {
                                    return (<></>)
                                }
                            }}
                        >

                            <Can
                                role={user.roleId}
                                perform="bots:show:page:users"
                                yes={() => {
                                    console.log("Rendering MenuItem for NoBot");
                                    return (
                                        <MenuItem key={0} value={'NoBot'}>
                                            <Checkbox
                                                size="small"
                                                color="primary"
                                                checked={selectModeList.includes('NoBot')}
                                                onChange={() => handleCheckboxChange('NoBot')}
                                            />
                                            <ListItemText primary={'Bot'} />
                                        </MenuItem>
                                    );
                                }}
                                no={() => null}
                            />
                            <Can
                                role={user.roleId}
                                perform="tickets:pending:all"
                                yes={() => {
                                    console.log("Rendering MenuItem for pending tickets");
                                    return (
                                        <MenuItem key={1} value={i18n.t("columnName.pending")}>
                                            <Checkbox
                                                style={{
                                                    color: "grey",
                                                }}
                                                size="small"
                                                color="primary"
                                                checked={selectModeList.includes(i18n.t("columnName.pending"))}
                                                onChange={() => handleCheckboxChange(i18n.t("columnName.pending"))}
                                            />
                                            <ListItemText primary={i18n.t("columnName.pending")} />
                                        </MenuItem>
                                    );
                                }}
                                no={() => null}
                            />

                            <MenuItem key={2} value={i18n.t("columnName.open")}>
                                <Checkbox
                                    style={{
                                        color: "grey",
                                    }}
                                    size="small"
                                    color="primary"
                                    checked={selectModeList.includes(i18n.t("columnName.open"))}
                                />
                                <ListItemText primary={i18n.t("columnName.open")} />
                            </MenuItem>

                            <MenuItem key={3} value={i18n.t("columnName.paused")}>
                                <Checkbox
                                    style={{
                                        color: "grey",
                                    }}
                                    size="small"
                                    color="primary"
                                    checked={selectModeList.includes(i18n.t("columnName.paused"))}
                                />
                                <ListItemText primary={i18n.t("columnName.paused")} />
                            </MenuItem>

                            <MenuItem key={4} value={i18n.t("columnName.closed")}>
                                <Checkbox
                                    style={{
                                        color: "grey",
                                    }}
                                    size="small"
                                    color="primary"
                                    checked={selectModeList.includes(i18n.t("columnName.closed"))}
                                />
                                <ListItemText primary={i18n.t("columnName.closed")} />
                            </MenuItem>

                            <MenuItem key={5} value={i18n.t("columnName.group")}>
                                <Checkbox
                                    style={{
                                        color: "grey",
                                    }}
                                    size="small"
                                    color="primary"
                                    checked={selectModeList.includes(i18n.t("columnName.group"))}
                                />
                                <ListItemText primary={i18n.t("columnName.group")} />
                            </MenuItem>
                        </Select>
                    </FormControl>
                </div>
            </Box>

            {showSearchBar && (
                <Grid container spacing={1} className={`${classes.ticketSearchLine} search-block`}>
                    <Grid item xs={12} md={12}>
                        <InputBase
                            className={classes.FormControl}
                            placeholder={i18n.t("tickets.search.ticketId")}
                            defaultValue={searchById}
                            onChange={handleChangeTicketId}
                            InputProps={{ sx: { borderRadius: 0 } }}
                        />
                    </Grid>
                    {!disableControls && (
                        <>
                            <Grid item xs={12} md={4} >
                                {isSearched && selectedQueueIds && selectedQueueIds.length > 0 ?
                                    <Chip
                                        label={selectedQueueIds.length + i18n.t("tickets.search.selectedQueues")}
                                        style={{ marginRight: 5, marginBottom: 5 }} />
                                    : (
                                        <AsyncSelect
                                            url="/queue"
                                            multiple={true}
                                            width="100%"
                                            disableControls={disableControls}
                                            isSearched={isSearched}
                                            defaultValue={selectedQueueIds}
                                            setListQueues={setListQueues}
                                            disabled={disableControls}
                                            isOptionDisabled={(option) => disableControls || option.id === -1} // Disable options as well when disableControls is true
                                            addFirstOption={{ id: -1, name: i18n.t("tickets.search.noQueues"), color: 'grey' }}
                                            label={i18n.t("ticketsQueueSelect.placeholder")}
                                            onChange={(event, values) => {
                                                setInternalSelectedQueueIds(values.map((value) => value.id))
                                            }}
                                            disableCloseOnSelect
                                            renderTags={(tags, getCustomizedTagProps) => (!tags || tags.length === 0 ? i18n.t("ticketsQueueSelect.placeholder") : tags.map((option, index) => {
                                                return (<Chip
                                                    label={option.name}
                                                    size='medium'
                                                    style={{ backgroundColor: option.color, color: chroma.contrast(option.color, 'white') > 2 ? 'white' : 'black' }}
                                                    {...getCustomizedTagProps({ index })}
                                                />)
                                            }
                                            ))}
                                            renderOption={(option, { selected }) => (
                                                <React.Fragment>
                                                    <Checkbox
                                                        style={{
                                                            color: option.color,
                                                        }}
                                                        size="small"
                                                        color="primary"
                                                        checked={selected}
                                                    />
                                                    <ListItemText primary={option.name} />
                                                </React.Fragment>
                                            )}
                                        />)}
                            </Grid>
                            <Grid item xs={12} md={4} >
                                {isSearched && tagIds && tagIds.length > 0 ?
                                    <Chip
                                        label={tagIds.length + i18n.t("tickets.search.selectedTags")}
                                        style={{ marginRight: 5, marginBottom: 5 }} />
                                    : (
                                        <SelectTags
                                            onChange={(value) => { setTagIds(value.map((tag) => tag.id)) }}
                                            defaultValue={tagIds}
                                            isSearched={isSearched}
                                            listTags={listTags}
                                            setListTags={setListTags}
                                            disabled={disableControls}
                                            outlined>
                                        </SelectTags>
                                    )}
                            </Grid>
                            <Grid item xs={12} md={4} >
                                {isSearched && connectionIds && connectionIds.length > 0 ?
                                    <Chip
                                        label={connectionIds.length + i18n.t("tickets.search.selectedConnections")}
                                        style={{ marginRight: 5, marginBottom: 5 }} />
                                    : (
                                        <AsyncSelect url="/whatsapp"
                                            isSearched={isSearched}
                                            listConnections={listConnections}
                                            setListConnections={setListConnections}
                                            disabled={disableControls}
                                            multiple={true} label={i18n.t("tickets.search.connections")} width="100%"
                                            dictKey="whatsapps"
                                            onChange={(event, value) => { setConnectionIds(value.map((connection) => connection.id)) }}>
                                        </AsyncSelect>
                                    )}
                            </Grid>
                            <Grid item xs={12} md={4} >
                                {isSearched && userIds && userIds.length > 0 ?
                                    <Chip
                                        label={userIds.length + i18n.t("tickets.search.selectedUsers")}
                                        style={{ marginRight: 5, marginBottom: 5 }} />
                                    : (
                                        <AsyncSelect
                                            defaultValue={userIds}
                                            listUsers={listUsers}
                                            setListUsers={setListUsers}
                                            url="/users"
                                            disabled={disableControls}
                                            isSearched={isSearched}
                                            multiple={true} label={i18n.t("tickets.search.users")} dictKey="users" width="100%"
                                            onChange={(event, value) => { setUserIds(value.map((user) => user.id)) }}>
                                        </AsyncSelect>
                                    )}
                            </Grid>
                            <Grid item xs={12} md={4} >
                                <FormControl variant="outlined" className={classes.smallFormControl} fullWidth>
                                    <InputLabel id="search-date-from-label">{i18n.t("tickets.search.from")}</InputLabel>
                                    <OutlinedInput
                                        label={i18n.t("tickets.search.from")}
                                        ref={inputRefDateFrom}
                                        onFocus={() => inputRefDateFrom.current.children[0].type = "date"}
                                        onBlur={() => inputRefDateFrom.current.children[0].type = "text"}
                                        type="text"
                                        placeholder={i18n.t("tickets.search.from")}
                                        disabled={disableControls}
                                        defaultValue={dateFrom}
                                        fullWidth
                                        onChange={(event) => { setDateFrom(event.target.value) }}></OutlinedInput>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={4} >
                                <FormControl variant="outlined" className={classes.smallFormControl} fullWidth>
                                    <InputLabel id="search-date-to-label">{i18n.t("tickets.search.to")}</InputLabel>
                                    <OutlinedInput
                                        label={i18n.t("tickets.search.to")}
                                        type="text"
                                        fullWidth
                                        ref={inputRefDateTo}
                                        disabled={disableControls}
                                        defaultValue={dateTo}
                                        onFocus={() => inputRefDateTo.current.children[0].type = "date"}
                                        onBlur={() => inputRefDateTo.current.children[0].type = "text"}
                                        placeholder={i18n.t("tickets.search.to")}
                                        onChange={(event) => { setDateTo(event.target.value) }}></OutlinedInput>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={4} style={{ marginTop: 5 }}>
                                {isSearched && selectedStatuses && selectedStatuses.length > 0 ? selectedStatuses.map((item, index) => (
                                    <Chip key={index}
                                        label={
                                            item === 'paused'
                                                ? i18n.t("columnName.paused")
                                                : item === 'open'
                                                    ? 'Atendimento'
                                                    : item === 'pending'
                                                        ? i18n.t("columnName.pending")
                                                        : i18n.t("columnName.closed")
                                        }
                                        style={{ marginRight: 5, marginBottom: 5 }} />
                                )) :
                                    (
                                        <SelectStatus isSearched={isSearched} defaultValue={selectedStatuses} onChange={setSelectedStatuses} disabled={disableControls} outlined></SelectStatus>
                                    )}
                            </Grid>
                            <Grid item xs={12} md={4} style={{ marginTop: 5 }}>
                                {selectedUnreadMessageOption == 'customClient' || selectedUnreadMessageOption == 'customMe' ? (
                                    <FormControl variant="outlined" className={classes.smallFormControl} fullWidth>
                                        <InputLabel id="input-time-label">minutos</InputLabel>
                                        <OutlinedInput
                                            label="minutos"
                                            type="number"
                                            placeholder="   "
                                            disabled={disableControls}
                                            fullWidth
                                            onChange={(event) => {
                                                selectedUnreadMessageOption == 'customClient' ?
                                                    setLastClientActivityClient(event.target.value) : setLastClientActivityMe(event.target.value)
                                            }}
                                        />
                                        <Button size="small" className={classes.resetButton} onClick={() => setSelectedUnreadMessage('')}>Resetar</Button>
                                    </FormControl>
                                ) : (
                                    <FormControl variant="outlined" className={classes.smallFormControl} fullWidth>
                                        <InputLabel id="select-option-label">{i18n.t("tickets.search.unanswered")}</InputLabel>
                                        <Select
                                            labelId="select-option-label"
                                            value={selectedUnreadMessageOption}
                                            onChange={handleUnanswered}
                                            defaultValue={selectedUnreadMessageOption}
                                            disabled={disableControls}
                                            label={i18n.t("tickets.search.unanswered")}
                                        >
                                            <MenuItem value="">{i18n.t("tickets.search.unansweredDisabled")}</MenuItem>
                                            <MenuItem value="allClient">{i18n.t("tickets.search.unansweredClient")}</MenuItem>
                                            <MenuItem value="allMe">{i18n.t("tickets.search.unansweredMe")}</MenuItem>
                                            <MenuItem value="customClient">{i18n.t("tickets.search.unansweredTimeClient")}</MenuItem>
                                            <MenuItem value="customMe">{i18n.t("tickets.search.unansweredTimeMe")}</MenuItem>
                                        </Select>
                                    </FormControl>
                                )}
                            </Grid>
                        </>
                    )}
                    <Grid item xs={6} md={2} className={classes.buttonFilter}>
                        <Button variant="contained" onClick={handleClean} fullWidth>{i18n.t("tickets.buttons.clear")}</Button>
                    </Grid>
                    <Grid item xs={6} md={2} className={classes.buttonFilter}>
                        <Button variant="contained" style={{ backgroundColor: "#2ecc71" }}
                            onClick={handleSearch} fullWidth>{i18n.t("tickets.buttons.search")}</Button>
                    </Grid>
                </Grid>
            )}
            <Box display="flex" justifyContent="center">
                {Object.keys(tasks).map((columnName) => {
                    if (selectedStatuses.length > 0) {
                        const selectedStatusColumnNames = selectedStatuses.map(status => getStatusColumnName(status.id));
                        if (!selectedStatusColumnNames.includes(columnName)) {
                            return null;
                        }
                    }
                    if (columnName === "NoBot" || columnName === i18n.t("columnName.pending")) {
                        return (
                            <Can
                                key={columnName}
                                role={user.roleId}
                                perform={columnName === "NoBot" ? "bots:show:page:users" : "tickets:pending:all"}
                                yes={() => (
                                    selectModeList.includes(columnName) && (
                                        <Box className={`boxesGrid ${classes.boxGridTickets} size-${selectModeList.length}`} mx={1} onDragOver={(e) => onDragOver(e)}>
                                            <Paper elevation={1} className={gridOpened[columnName] === true ? classes.paperBoxes : classes.boxMinimized}>
                                                <Typography className={classes.headH6} variant="h6" sx={{ position: 'sticky', top: 0, alignItems: 'center', gap: 1, display: 'flex' }}>
                                                    {columnName === 'NoBot' ? <>Bot</> : columnName}
                                                    <button onClick={() => handleColumnClick(columnName)} className={classes.boxColumnButton}>
                                                        {gridOpened[columnName] === true ? <FaMinus /> : <FaPlus />}
                                                    </button>
                                                </Typography>
                                                <>
                                                    {columnName === 'NoBot' && showBotList && (getSettingValue('enableSessionBot') === 'enabled' || user.roleId === 1) && (
                                                        <TicketsList
                                                            status="bot"
                                                            mode="grid"
                                                            searchParam={searchParam}
                                                            searchById={searchById}
                                                            showAll={showAllTickets}
                                                            sortDir={sortAscending ? 'ASC' : 'DESC'}
                                                            searchOnMessages={searchOnMessages}
                                                            selectedQueueIds={selectedQueueIds}
                                                            selectedTagIds={tagIds}
                                                            selectedConnectionIds={connectionIds ? connectionIds : null}
                                                            selectedUserIds={userIds ? userIds : null}
                                                            dateFrom={dateFrom}
                                                            dateTo={dateTo}
                                                            selectedStatuses={selectedStatuses}
                                                            forceSearch={forceSearch}
                                                            lastClientActivityMe={lastClientActivityMe ? lastClientActivityMe : null}
                                                            lastClientActivityClient={lastClientActivityClient ? lastClientActivityClient : null}
                                                            unreadMessagesSearchClient={unreadMessagesSearchClient}
                                                            unreadMessagesSearchMe={unreadMessagesSearchMe}
                                                        />)}

                                                    {columnName === i18n.t("columnName.pending") && (
                                                        <TicketsList
                                                            mode="grid"
                                                            status="pending"
                                                            sortDir={sortAscending ? 'ASC' : 'DESC'}
                                                            searchParam={searchParam}
                                                            searchById={searchById}
                                                            showAll={showAllTickets}
                                                            searchOnMessages={searchOnMessages}
                                                            selectedQueueIds={selectedQueueIds}
                                                            selectedTagIds={tagIds}
                                                            selectedConnectionIds={connectionIds ? connectionIds : null}
                                                            selectedUserIds={userIds ? userIds : null}
                                                            dateFrom={dateFrom}
                                                            dateTo={dateTo}
                                                            selectedStatuses={selectedStatuses}
                                                            forceSearch={forceSearch}
                                                            lastClientActivityMe={lastClientActivityMe ? lastClientActivityMe : null}
                                                            lastClientActivityClient={lastClientActivityClient ? lastClientActivityClient : null}
                                                            unreadMessagesSearchClient={unreadMessagesSearchClient}
                                                            unreadMessagesSearchMe={unreadMessagesSearchMe}
                                                        />
                                                    )}
                                                </>
                                            </Paper>
                                        </Box>
                                    )
                                )}
                                no={() => null}
                            />
                        );
                    }
                    return (
                        selectModeList.includes(columnName) && (
                            <Box key={columnName} className={`boxesGrid ${classes.boxGridTickets} size-${selectModeList.length}`} mx={1} onDragOver={(e) => onDragOver(e)}>
                                <Paper elevation={1} className={gridOpened[columnName] === true ? classes.paperBoxes : classes.boxMinimized}>
                                    <Typography className={classes.headH6} variant="h6" sx={{ position: 'sticky', top: 0, alignItems: 'center', gap: 1, display: 'flex' }}>
                                        {columnName === 'NoBot' ? <>Bot</> : columnName}
                                        <button onClick={() => handleColumnClick(columnName)} className={classes.boxColumnButton}>
                                            {gridOpened[columnName] === true ? <FaMinus /> : <FaPlus />}
                                        </button>
                                    </Typography>
                                    <>
                                        {columnName === 'NoBot' && showBotList && (getSettingValue('enableSessionBot') === 'enabled' || user.roleId === 1) && (
                                            <TicketsList
                                                status="bot"
                                                mode="grid"
                                                searchParam={searchParam}
                                                searchById={searchById}
                                                showAll={showAllTickets}
                                                sortDir={sortAscending ? 'ASC' : 'DESC'}
                                                searchOnMessages={searchOnMessages}
                                                selectedQueueIds={selectedQueueIds}
                                                selectedTagIds={tagIds}
                                                selectedConnectionIds={connectionIds ? connectionIds : null}
                                                selectedUserIds={userIds ? userIds : null}
                                                dateFrom={dateFrom}
                                                dateTo={dateTo}
                                                selectedStatuses={selectedStatuses}
                                                forceSearch={forceSearch}
                                                lastClientActivityMe={lastClientActivityMe ? lastClientActivityMe : null}
                                                lastClientActivityClient={lastClientActivityClient ? lastClientActivityClient : null}
                                                unreadMessagesSearchClient={unreadMessagesSearchClient}
                                                unreadMessagesSearchMe={unreadMessagesSearchMe}
                                            />)}

                                        {columnName === i18n.t("columnName.pending") && (
                                            <TicketsList
                                                mode="grid"
                                                status="pending"
                                                sortDir={sortAscending ? 'ASC' : 'DESC'}
                                                searchParam={searchParam}
                                                searchById={searchById}
                                                showAll={showAllTickets}
                                                searchOnMessages={searchOnMessages}
                                                selectedQueueIds={selectedQueueIds}
                                                selectedTagIds={tagIds}
                                                selectedConnectionIds={connectionIds ? connectionIds : null}
                                                selectedUserIds={userIds ? userIds : null}
                                                dateFrom={dateFrom}
                                                dateTo={dateTo}
                                                selectedStatuses={selectedStatuses}
                                                forceSearch={forceSearch}
                                                lastClientActivityMe={lastClientActivityMe ? lastClientActivityMe : null}
                                                lastClientActivityClient={lastClientActivityClient ? lastClientActivityClient : null}
                                                unreadMessagesSearchClient={unreadMessagesSearchClient}
                                                unreadMessagesSearchMe={unreadMessagesSearchMe}
                                            />
                                        )}

                                        {columnName === i18n.t("columnName.open") && (
                                            <TicketsList
                                                status="open"
                                                mode="grid"
                                                searchParam={searchParam}
                                                searchById={searchById}
                                                showAll={showAllTickets}
                                                sortDir={sortAscending ? 'ASC' : 'DESC'}
                                                searchOnMessages={searchOnMessages}
                                                selectedQueueIds={selectedQueueIds}
                                                selectedTagIds={tagIds}
                                                selectedConnectionIds={connectionIds ? connectionIds : null}
                                                selectedUserIds={userIds ? userIds : null}
                                                dateFrom={dateFrom}
                                                dateTo={dateTo}
                                                selectedStatuses={selectedStatuses}
                                                forceSearch={forceSearch}
                                                lastClientActivityMe={lastClientActivityMe ? lastClientActivityMe : null}
                                                lastClientActivityClient={lastClientActivityClient ? lastClientActivityClient : null}
                                                unreadMessagesSearchClient={unreadMessagesSearchClient}
                                                unreadMessagesSearchMe={unreadMessagesSearchMe}
                                            />
                                        )}

                                        {columnName === i18n.t("columnName.closed") && (
                                            <TicketsList
                                                status="closed"
                                                mode="grid"
                                                searchParam={searchParam}
                                                searchById={searchById}
                                                showAll={showAllTickets}
                                                sortDir={sortAscending ? 'ASC' : 'DESC'}
                                                searchOnMessages={searchOnMessages}
                                                selectedQueueIds={selectedQueueIds}
                                                selectedTagIds={tagIds}
                                                selectedConnectionIds={connectionIds ? connectionIds : null}
                                                selectedUserIds={userIds ? userIds : null}
                                                dateFrom={dateFrom}
                                                dateTo={dateTo}
                                                selectedStatuses={selectedStatuses}
                                                forceSearch={forceSearch}
                                                lastClientActivityMe={lastClientActivityMe ? lastClientActivityMe : null}
                                                lastClientActivityClient={lastClientActivityClient ? lastClientActivityClient : null}
                                                unreadMessagesSearchClient={unreadMessagesSearchClient}
                                                unreadMessagesSearchMe={unreadMessagesSearchMe}
                                            />
                                        )}

                                        {columnName === i18n.t("columnName.paused") && (
                                            <TicketsList
                                                status="paused"
                                                mode="grid"
                                                searchParam={searchParam}
                                                searchById={searchById}
                                                showAll={showAllTickets}
                                                sortDir={sortAscending ? 'ASC' : 'DESC'}
                                                searchOnMessages={searchOnMessages}
                                                selectedQueueIds={selectedQueueIds}
                                                selectedTagIds={tagIds}
                                                selectedConnectionIds={connectionIds ? connectionIds : null}
                                                selectedUserIds={userIds ? userIds : null}
                                                dateFrom={dateFrom}
                                                dateTo={dateTo}
                                                selectedStatuses={selectedStatuses}
                                                forceSearch={forceSearch}
                                                lastClientActivityMe={lastClientActivityMe ? lastClientActivityMe : null}
                                                lastClientActivityClient={lastClientActivityClient ? lastClientActivityClient : null}
                                                unreadMessagesSearchClient={unreadMessagesSearchClient}
                                                unreadMessagesSearchMe={unreadMessagesSearchMe}
                                            />
                                        )}

                                        {columnName === i18n.t("columnName.group") && (
                                            <TicketsList
                                                status="group"
                                                showAll={userCanInteractWithAllGroups ? showAllTickets:false}
                                                mode="grid"
                                                searchParam={searchParam}
                                                searchById={searchById}
                                                sortDir={sortAscending ? 'ASC' : 'DESC'}
                                                searchOnMessages={searchOnMessages}
                                                selectedQueueIds={selectedQueueIds}
                                                selectedTagIds={tagIds}
                                                selectedConnectionIds={connectionIds ? connectionIds : null}
                                                selectedUserIds={userIds ? userIds : null}
                                                dateFrom={dateFrom}
                                                dateTo={dateTo}
                                                selectedStatuses={selectedStatuses}
                                                forceSearch={forceSearch}
                                                lastClientActivityMe={lastClientActivityMe ? lastClientActivityMe : null}
                                                lastClientActivityClient={lastClientActivityClient ? lastClientActivityClient : null}
                                                unreadMessagesSearchClient={unreadMessagesSearchClient}
                                                unreadMessagesSearchMe={unreadMessagesSearchMe}
                                                isGroup={true}
                                            />
                                        )}
                                    </>
                                </Paper>
                            </Box>
                        )
                    );
                })}
            </Box>

        </>
    );
};

export default TicketsGridManager;
